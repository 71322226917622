<template>
  <div>
    <div class="pc-wrap" style="position: relative">
      <img
        src="https://img.runzehui.net/2022/01/17/6a6b4202201171007335247.jpg"
        alt=""
      />
    </div>

    <div class="container pc-wrap">
      <div class="pc-content">
        <div class="title">润泽汇数字商业平台简介</div>
        <div class="subtitle">
          INTRODUCTION TO RUNZEHUI DIGITAL BUSINESS PLATFORM
        </div>
        <div class="paragraph">
          <p>
            润泽汇数字福利商业平台是一个以数字化福利分发为主营业务的为中小微企业提供全服务的平台，通过福利分发为企业采购降本，管理增效。达到福利增值的目的。
          </p>
          <p>
            同时润泽汇为福利企业倾力打造数字化商业联盟，共建联盟，共享资源，行业交流，信息互动；免费订制人力和营销类SAAS管理工具，打造润泽云学院和其它各各项委员会，为联盟企业发展赋能，助力联盟企业全方位发展。
          </p>
        </div>

        <img
          src="https://img.runzehui.net/2022/01/17/7b3b7202201171023338168.jpg"
          alt=""
        />

        <div class="icons">
          <div class="icon-item" v-for="(item, index) in icons" :key="index">
            <img :src="item.img" alt="" />
            <div class="icon-info" :class="{ noborder: index == 0 }">
              <div class="icon-title">{{ item.title }}</div>
              <div class="icon-content" v-html="item.content"></div>
            </div>
          </div>
        </div>

        <div>
          <div class="title" style="text-align: left; margin: 40px 0 20px">
            弹性福利 助力企业打造幸福职场
          </div>
          <div class="qiyes">
            <div v-for="(item, index) in qiyes" :key="index" class="qiye-item">
              <img :src="item.img" alt="" />
              <div class="qiye-con">
                <div class="qiye-title">{{ item.title }}</div>
                <div class="qiye-content" v-html="item.content"></div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="title" style="text-align: left; margin: 40px 0 20px">
            数字福利助力企业降本
          </div>
          <div class="qiyes">
            <div
              v-for="(item, index) in qiyes2"
              :key="index"
              class="qiye-item text-c nobg"
            >
              <img class="icon" :src="item.img" alt="" />
              <div class="qiye-con">
                <div class="qiye-title">{{ item.title }}</div>
                <div class="qiye-content" v-html="item.content"></div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="title" style="text-align: left; margin: 40px 0 20px">
            数字福利助力企业增效
          </div>
          <div class="qiyes">
            <div
              v-for="(item, index) in qiyes3"
              :key="index"
              class="qiye-item text-c"
            >
              <img :src="item.img" alt="" />
              <div class="qiye-con">
                <div class="qiye-content" v-html="item.content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pc-wrap" style="background: #f6f5f5">
      <div class="pc-content">
        <img
          src="https://img.runzehui.net/2022/01/17/32d7e202201171400394100.jpg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icons: [
        {
          img: "https://img.runzehui.net/2022/01/17/2e724202201171034523924.jpg",
          title: "信鸽商城",
          content: "企业员工优享/福利<br />线上线下门店互通",
        },
        {
          img: "https://img.runzehui.net/2022/01/17/ea17120220117103501989.jpg",
          title: "润品商城",
          content: "古树红茶 酱酒 红木家具<br />及其他自营联营产品",
        },
        {
          img: "https://img.runzehui.net/2022/01/17/1c6b9202201171035166798.jpg",
          title: "溯源商城",
          content: "溯源产地产品 供应链可追溯<br />可信品牌定制",
        },
        {
          img: "https://img.runzehui.net/2022/01/17/b0cfa202201171035309036.jpg",
          title: "定制商城",
          content: "企业SAAS系统 企业福利<br />办公/服务等个性化定制",
        },
      ],
      qiyes: [
        {
          img: "https://img.runzehui.net/2022/01/17/41471202201171320162147.jpg",
          title: "企业",
          content: `<p>
                    灵活高效：一站式解决福利发放难等问题，增强员工幸福感，数字化管理助力企业运营
                  </p>
                  <p>
                    员工满意度高；个性化关怀帮助企业营造文化氛围，自主性高。提升员工归属感，激励留才。
                  </p>`,
        },
        {
          img: "https://img.runzehui.net/2022/01/17/4999b202201171329448192.jpg",
          title: "主管部门",
          content: `<p>
                    高效便捷：规避繁冗操作，后台操作灵活简单，站式规划，多元化配置福利，降低力工成本。
                  </p>
                  <p>
                    数据精准直观：福利数据详实，分析，员工动态清晰掌握，点对点管理更加精准。
                  </p>`,
        },
        {
          img: "https://img.runzehui.net/2022/01/17/284f1202201171332003848.jpg",
          title: "员工",
          content: `<p>
                    选择丰富：覆盖衣食住行游购娱等全方位生活场景，自选福利更贴近生活实际所需
                  </p>
                  <p>
                    福利价值感强：资源整合凸显综合优势，人性化体验，多维度享受，福利积分价值感强，福利感知度高。
                  </p>`,
        },
      ],
      qiyes2: [
        {
          img: "https://img.runzehui.net/2022/01/17/8938920220117142032647.jpg",
          title: "一重",
          content: `
            <p style="color: #000">让福利更超值</p>
            <p>员工便捷采购自主性强
            <p>源头供应链一议价能力强</p>
            <p>消费场景全覆盖一可选择性多</p>
            <p>大牌溯源一可信度高</p>
          `,
        },
        {
          img: "https://img.runzehui.net/2022/01/17/19556202201171420486569.jpg",
          title: "二重",
          content: `
            <p style="color: #000">等额企业消费积分免费送</p>
            <p>50%福采积分 + 50%集采集分</p>
            <p>积分可兑换企业招待用茶、酒</p>
            <p>积分可兑换企业办公用品、劳保用品等</p>
            <p>积分可作为奖励，激励员工</p>    
          `,
        },
        {
          img: "https://img.runzehui.net/2022/01/17/5439c202201171420595469.jpg",
          title: "三重",
          content: `
            <p style="color: #000">等额福豆免费领</p>
            <p>平台赠送企业等额福豆给予员工。</p>
            <p>福豆用于消费抵扣现金。</p>
            <p>员工消费折上折</p>
          `,
        },
      ],
      qiyes3: [
        {
          img: "https://img.runzehui.net/2022/01/17/48d1e202201171353114216.jpg",
          content: `
            <p>9大系统模块助力企业提升管理效率</p>
            <p>定制专属福利商城，共享信鸽商品库，福利分发更高效</p>
            <p>共享平台流量，助力企业产品动销</p>
              `,
        },
        {
          img: "https://img.runzehui.net/2022/01/17/a6e21202201171353373562.jpg",
          content: `
          <p>8大培训体系助力企业提升运营能力</p>
          <p>积分线上兑换培训课程</p>
          `,
        },
        {
          img: "https://img.runzehui.net/2022/01/17/a6e21202201171353373562.jpg",
          content: `
            <p>企业员工商城消费，获取平台利润分红。</p>
            <p>企业伞下交易全关联，再给企业添利润。</p>
          `,
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
img {
  display: block;
  width: 100%;
}

.paragraph {
  margin-bottom: 40px;

  p {
    font-size: 13px;
    line-height: 1.6;
    text-indent: 26px;
    color: #616161;
  }
}

.icons {
  display: flex;
  padding: 40px 0;

  .icon-item {
    flex: 1;
    text-align: center;

    img {
      width: 32%;
      height: auto;
      margin: auto;
    }

    .icon-info {
      border-left: 1px solid #ccc;

      &.noborder {
        border-left: 0;
      }
    }

    .icon-title {
      font-size: 16px;
      margin-bottom: 20px;
    }

    .icon-content {
      font-size: 12px;
      line-height: 1.6;
      color: #666;
    }
  }
}

.qiyes {
  display: flex;

  .qiye-item {
    flex: 1;
    margin-left: 20px;
    background-color: #eee;

    &.nobg {
      background: none;
    }

    &:first-of-type {
      margin-left: 0;
    }

    .qiye-con {
      padding: 10px 15px 20px;
    }

    .qiye-title {
      font-size: 18px;
      font-weight: bold;
    }

    .qiye-content {
      font-size: 14px;
      color: #666;
    }

    .icon {
      width: 24%;
      margin: auto;
    }
  }
}
</style>

<style scoped lang="scss">
@media (min-width: 320px) and (max-width: 430px) {
  .icons {
    display: block;
    padding: 5px 0;

    .icon-item {
      display: inline-block;
      width: 50%;
      margin: 20px 0;
      .icon-info {
        border-left: 0;
      }
    }
  }

  .qiyes {
    display: block;
    .qiye-item {
      margin-top: 20px;
      margin-left: 0;
    }
  }
}
</style>